<template>
    <div class="hero uk-container uk-container-expand uk-background-cover" >
        <div class="background uk-position-absolute uk-transform-center" >
            <div class="background-inner uk-position-absolute uk-transform-center"
                 uk-parallax="bgy: 75,-75; easing: -0.25"
                 :style="heroBackgroundStyle">

            </div>
        </div>
        <div class="background-overlay "></div>
        <div class="content uk-container-large uk-margin-auto ">
            <div class="uk-overlay uk-light uk-position-center">
              <h1 tabindex="0" aria-live="assertive">{{title ? title : ''}}</h1>
            </div>


        </div>
    </div>
</template>

<script>

    //    import asyncOperations from "@/client/extensions/composition/asyncOperations";
    export default {
        props: {
            title: {
                type: [String, Boolean],
                default: false
            },
            background: {
                type: [String],
                default: 'ecommerce/headers/sample_header_2.jpg'
            }
        },
        components: {

        },
        data: function () {
            return {

            };
        },
        computed: {
            heroBackgroundStyle () {
                let url = utilities.requireAsset(this.background);
                return {
                    backgroundImage : "url('"+url+"')",

                };
            },

        },
        methods: {

        },
        mounted () {

        },
    }
</script>

<style scoped lang="scss">

    .hero {
        position: relative;
        height: 150px;

        .background {
            height: 100%;
            width: 100%;
            left: 50%;
            top: 50%;

            z-index: 0;
            overflow: hidden;


            .background-inner {

                height: calc(100% + 40px);
                width: calc(100% + 40px);
                left: calc(50% - 20px);
                top: calc(50% - 20px);

                background-size: cover;
                filter: blur(2px);
                -webkit-filter: blur(2px);
            }
        }

        .background-overlay {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background: rgba(0,0,0,0.35);
            z-index: 0;
        }

        .content {
            position: relative;
            z-index: 1;
            height: 100%;
        }
    }



</style>
